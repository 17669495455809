@font-face {
    font-family: "password";
    font-style: normal;
    //font-weight: 400;
    src: url(../../assets/fonts/password.ttf);
    font-display: swap;
}

@font-face {
    font-family: "cabin-regular";
    font-style: normal;
    //font-weight: 400;
    // src: url(../../assets/fonts/Cabin-Regular.woff);
    // src: url(../../assets/fonts/Ubuntu-Regular.ttf);
    src: url(../../assets/fonts/Roboto-Regular.ttf);
    font-display: swap;
}


@font-face {
    font-family: "roboto-medium";
    font-style: normal;
    font-weight: 500;
    // src: url(../../assets/fonts/Cabin-Regular.woff);
    // src: url(../../assets/fonts/Ubuntu-Regular.ttf);
    src: url(../../assets/fonts/Roboto-Medium.ttf);
    font-display: swap;
}


@font-face {
    font-family: "roboto-bold";
    font-style: normal;
    font-weight: 700;
    // src: url(../../assets/fonts/Cabin-Regular.woff);
    // src: url(../../assets/fonts/Ubuntu-Regular.ttf);
    src: url(../../assets/fonts/Roboto-Bold.ttf);
    font-display: swap;
}

@font-face {
    font-family: "cooper-black";
    font-style: normal;
    font-weight: 400;
    // src: url(../../assets/fonts/Cabin-Regular.woff);
    // src: url(../../assets/fonts/Ubuntu-Regular.ttf);
    src: url(../../assets/fonts/Cooper-BlackRegular.ttf);
    font-display: swap;
}

