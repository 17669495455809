@import "abstracts/variables";

@import "base/animations";
@import "base/font";
@import "base/base";

@import "component/common";
@import "component/element";
@import "component/notification";


.container{
    font-size: 1.6rem;
}

.common-header-main{
    padding-top: 2em;
    padding-bottom: 1em;
    background: #FFFFFF;
    border-radius: 0px 0px 18px 18px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    
    .badge{
    height: 1.3rem;
    position: fixed;
    // //font-weight: 700;
    min-width: 1.3rem;
    font-size: 0.8rem;
    line-height: 1.3rem;
    background:#FFE7E7 ;
    color:$text-color--secondary ;
    }  
    .animation-icon{
        animation: shake 0.4s infinite;
    }
}
.common-header-main2{
    // height: 24.5%;
    border-radius: 0px 0px 0px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    padding-top: 2em;
    background: #FFFFFF;

    .badge{
        height: 1.3rem;
        position: fixed;
        // //font-weight: 700;
        min-width: 1.3rem;
        font-size: 0.8rem;
        line-height: 1.3rem;
        background:#FFE7E7 ;
        color:$text-color--secondary ;
        }  
    }

    .animation-icon{
        animation: shake 0.4s infinite;
    }


.partial-register-modal{
    [data-rsbs-overlay]{
        color: #FDFDFF;
        // background: #373A2E;
        background: #3A2E2E;
    } 

      [data-rsbs-overlay]::-webkit-scrollbar{
        display: none;
    }
    [data-rsbs-scroll]::-webkit-scrollbar {
        display: none !important;   
    }
}
.partial-register-modal::-webkit-scrollbar {
    display: none !important;  
  }
//modal
.modal-content{
    min-height: 55em;
    border-radius: 1em;
    
    @media (max-width: 1200px) {
        min-height: 0;
    }
}
.partial-modal{ 
    [data-rsbs-overlay]{
        color: #FDFDFF;
        background: #373A2E;
    }
    [data-rsbs-scroll]::-webkit-scrollbar {
        display: none !important;   
    }

    .text-input{
        width: 100%;
    }

    @media (min-width: 800px) { 
    ._label{
        &.--text{
            color: #FDFDFF;
            // //font-weight: 500;
            font-size: 1.6rem;
        }
        &.--user{
            color: #FDFDFF;
            // //font-weight: 500;
            font-size: 1.9rem;
        }
        &.--title{
            font-size: 2rem;
            // //font-weight: 350;
            text-align: left;
            color: #FDFDFF;
            margin-top: 0.7em;
            margin-bottom: 0.7em;
        }
      }
    }

    .icon-clock{
        width: 18px;
        height: 18px;
        margin-right: 0.7em;
        margin-top: -0.3em;
    }

    .user-modal{
        display: flex;
        align-items: center;
        justify-content: center;
        .user-name{
            ._label{
                &.--user{
                    font-family: "roboto-medium";
                    font-size: 1.8rem;
                }
            }
            .span-number{
                font-size: 1.9rem;

            }
        }
    }
    .account-container{
        position: fixed;
        padding: 1em 1.7em;
        ._label{
            &.--title{
                display: flex;
                // //font-weight: 350;
                color: #FDFDFF;
                font-size : 2rem;
            }
        }
        .modal-listing{
            margin-top: 0.7em;
            height: 3em;
            align-items: center;
            ._label{
                &.--logout{
                    display: flex;
                    // //font-weight: 350;
                    text-align: left;
                    color: #FDFDFF;
                    font-size: 1.6rem;
                    align-items: start;
                }
                &.--name{
                    display: flex;
                    // //font-weight: 350;
                    text-align: left;
                    color: #FDFDFF;
                    font-size: 1.6rem;
                    align-items: start;
                }
                
            }
        }
    }
}
.modal-backdrop{
    z-index: 9998;
}
.backdrop-bottom{
    height: 100vh;
    position: relative;
    z-index: 1000 !important;
}
.empty-div{
    margin-top: 2em;
    height: 50vh;
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    .logo-image{
       margin-top: 3em;
       width: 168px;
       height: 168px;
       opacity: 0.3;
    }
    .loading-div {
        margin-top: -1em;
        font-size: 20px;
        font-style: italic;
        letter-spacing: 0.05em;
        color: #6351514f;
        font-family: "roboto-medium";
        // text-transform: uppercase;
    }

    .loading-lottie{
        // margin-top: 5.5em;
        width: 220px;
        margin-bottom: 0em;

        &.--two {
            width: 100px;
            margin-top: -1.5em;
        }
    }
}
.full-modal-box-container{
    height: 100%;
    z-index: 9999 !important;
    .lazy-loading {
        margin-top:1em;
        display: flex;
        justify-content: center;
        width: 6em;
    }
    &.login-modal{
        .login-container{
            .login-header{
                padding: 1em 0em;
                text-align: center;
                .col-div{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    ._label{
                        &.--icon{
                            // //font-weight: 400;
                            font-size: 2.4rem;
                            color: $text-color--primary;
                        }
                        &.--title{
                            font-family: "roboto-medium";
                            font-size: 2.4rem;
                            text-transform: uppercase;
                            color: $text-color--primary;
                        }
                        &.--btn{
                            // //font-weight: 400;
                            font-size: 1.6rem;
                            color: $text-color--secondary;
                        }
                    }
                }
            }
            .login-body{
                margin-top: 2em;
                ._label{
                    &.--note{
                        font-size: 2rem;
                        font-family: "roboto-medium";
                        color: $text-color--primary;
                    }
                    &.--text{
                        // //font-weight:400;
                        color:#707070;
                        font-size: 1.4rem;
                    }
                    &.--tag{
                        // //font-weight:400;
                        font-size: 1.4rem;
                        color:$color-primary;
                        text-decoration: 0.25rem $color-primary underline;
                    }
                    &.--forgot{
                        //font-weight:400;
                        font-size: 1.4rem;
                        color:$text-color--primary;
                    }
                }
                .tag-div{
                    margin-top: 2em;
                }
                .forgot-label{
                    margin-top: 0.6em;
                    margin-bottom: 1em;
                }
            }
        }
    }
    &.cart-modal{
        -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        .modal-body{
            .skeleton_image{
               margin: auto;
               margin-top: 1em;
               margin-bottom: 1em;
               width: 360px !important;
               height: 197px !important;
               border-radius: 10px;
            }
            .skeleton_food{
                margin-top: 0.4em;
                .skeleton_col{
                    margin: auto;
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                }
             }
            .skeleton_addon{
               padding: 0.2em;
               margin: 0.5em 0em; 
            }
            .m1{
                margin-top: 1.5em;
            }
            .food-image{
                width: 360px;
                height: 197px; 
                margin-top: 1em;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 2em;
                border-radius: 1.2em;
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
                .image{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 1.2em;
                }
            }
            .title{
                overflow: hidden;
            }
            ._label {
                &.--title{
                    font-family: "roboto-medium";
                    font-size: 1.8rem;
                    color: $text-color--primary;
                }
                &.--price{
                    display: block;
                    text-align: end;
                    font-family: "roboto-medium";
                    font-size: 1.8rem;
                    color: $text-color--primary;
                }
                &.--addon-name{
                    font-family: "roboto-medium";
                    font-size: 1.6rem;
                    color: $text-color--primary;
                }
                &.--addon-price{
                    display: block;
                    text-align: end;
                    font-family: "roboto-medium";

                    font-size: 1.4rem;
                    color: $text-color--primary;
                }
                &.--note{
                    font-family: "roboto-medium";
                    font-size: 1.6rem;
                    color: $text-color--primary;
                }
                &.--error{
                    font-size: 0.8em;
                    //font-weight: 500;
                }
                ._span{
                    &.--text{
                        //font-weight: 400;
                        font-size: 1.2rem;
                        color: $text-color--fourth;
                    }
                     &.--note{
                         //font-weight: 400;
                         font-size: 1.2rem;
                        color: $text-color--secondary;
                    }
                }
                &.--code{
                    font-size: 0.8em;
                    //font-weight: 500;
                    margin-left: -0.8em;
                    background: #dfdfdf;
                    height: 1.5em;
                    width: 3.5em;
                    border-radius: 0.3em;
                    margin-top: 0.8em;
                }   
                &.--error{
                    font-size: 0.8em;
                    //font-weight: 500;
                }   
            }
            ._p {
                &.--detail{
                    //font-weight: 400;
                    font-size: 1.2rem;
                    word-break: break-all;
                    color: $text-color--secondary;
                }
                &.--price{
                    display: block;
                    text-align: end;
                    //font-weight: 400;
                    font-size: 1.4rem;
                    color: $text-color--secondary;
                }
            }
            .food-order::-webkit-scrollbar {
                display: none !important;
              }
            .food-order{
                // height: 23em;
                overflow: auto;
                overflow: hidden;
                background: $background-color--primary;
                .food-title{
                    background: #FFFFFF;
                }
                .addon{
                    padding: 0.3em 0.4em;
                    .addon-container{
                        padding: 0.2em;
                        margin: 0.2em 0em;
                        background: #FFFFFF;
                        border-radius: 0.625em;
                        .addon-item{
                            margin: 0.5em 0em;
                        .form-check{
                            display: flex;
                            align-items: center;
                        .form-check-input[type=checkbox]{
                            height: 1em;
                            width: 1em;
                            overflow: hidden;
                            border-color: $color-primary;
                            border: 0.3rem solid $color-primary;
                        }
                        .form-check-input[type=checkbox]:checked{
                            background: $color-primary;
                            .form-check-label{
                                //font-weight: bold;
                                display: none;
                                font-size: 1.4rem;
                                color: $text-color--secondary;
                            }
                        }
                        .form-check-label{
                            //font-weight: 400;
                            display: none;
                            font-size: 1.4rem;
                            color: $text-color--secondary;
                        }
                        .form-check-input[type=checkbox]:checked:after{
                            margin-top: 0;
                            border-width: 0.19rem;
                            margin-left: 0.2em;
                        }
                        
                    }
                    }
                    }
    
                }
                .note-1{
                    height: 42.7vh;
                    margin-top: 0.2em;
                    background: #FFFFFF;
                }
            }
            .count_button{
                width: 36em;
                margin: auto;
                align-items: center;
                justify-content: center;
                position: relative;
            }
            .button{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    } 
    &.receipt-modal{
        .receipt-container{
            padding: 1em;
            .food-col{
                text-align: center;
            }
            .food-name{
                overflow: hidden;
            }
            .list-title{
                text-align:center ;
                border-top: 2px dashed #bbb;
                border-bottom: 2px dashed #bbb;
                .qty{
                    text-align: start;
                }
                .rm{
                    text-align: end;
                }
            }
            ._label{
                &.--title{
                    text-align: center;
                    font-size: 1.7rem;
                    //font-weight: 500;
                    border-radius: 3px;
                    color: $text-color--primary;
                    // border-left: 6px solid $color-primary;
                }
                &.--title2{
                    font-size: 1.7rem;
                    //font-weight: 500;
                    border-radius: 3px;
                    color: $text-color--primary;
                    border-left: 6px solid $color-primary;
                }
                &.--address{
                    //font-weight: 500;
                    font-size: 1.3rem;
                    text-align: center;
                    color: $text-color--primary;
                }
                &.--table{
                    //font-weight: 500;
                    font-size: 1.3rem;
                    text-align: center;
                    color: $text-color--primary;
                    margin: 0.6em 0em 0.35em 0em; 
                }
                &.--qty{
                    //font-weight: 500;
                    font-size: 1.3rem;
                    color: $text-color--primary;
                    margin: 0.4em 0em 0.35em 0em; 
                }
                &.--rm{
                    //font-weight: 500;
                    font-size: 1.3rem;
                    color: $text-color--primary;
                    margin: 0.5em 0em 0.35em 0em; 
                }
                &.--food{
                    //font-weight: 400;
                    font-size: 1.3rem;
                    color: $text-color--secondary;

                    span {
                        padding: 0.2em 0.5em;
                        background-color: #e2e2e7;
                        color: #83829a;
                    }
                }
                &.--detail{
                    font-size: 1.2rem;
                    //font-weight: 400;
                    color: $text-color--fourth;
                }
                &.--option{
                    font-size: 1.3rem;
                    //font-weight: 400;
                    color: $text-color--secondary;
                }
                &.--price{
                    font-size: 1.3rem;
                    //font-weight: 400;
                    color: $text-color--secondary;
                }
                &.--code{
                    font-size: 0.9em;
                    //font-weight: 500;
                    margin-left: 0em;
                    background: #dfdfdf;
                    height: 1.5em;
                    width: 3.5em;
                    border-radius: 0.3em;
                    margin-top: 0.8em;
                }   
            }
            .order-listing{
                padding: 0;
                margin-top: 0.7em;
            }
            .receipt-detail{
                margin-top: 1em;
                padding: 0.5em;
                border-top: 2px dashed #bbb;
                .detail{
                    align-items: center;
                    .amount-col{
                        text-align: end;
                    }
                }
                .total-paid{
                    margin-top: 1em;
                    border-top: 2px dashed #bbb;
                    border-bottom: 2px dashed #bbb;
                    ._label{
                        padding: 0 !important;
                        &.--data{
                            //font-weight: 400;
                            font-size: 1.5rem;
                            color: $text-color--secondary;
                        }
                        &.--data2{
                            //font-weight: 400;
                            font-size: 1.5rem;
                            color: $text-color--secondary;
                        }
                        
                      
                    }
                }
                ._label{
                    &.--data{
                        //font-weight: 400;
                        font-size: 1.3rem;
                        color: $text-color--secondary;
                    }
                    &.--data2{
                        //font-weight: 400;
                        font-size: 1.3rem;
                        color: $text-color--secondary;
                    }
                    
                  
                }
            }
            .payment-text{
                margin-top: 0.5em;
                margin-bottom: 0.7em;
            }
            .payment-info{
                padding: 0.4em;
                border-radius: 0.625em;

                &.--payment {
                    margin-top: 0em;
                    border: 2px dashed #bbb;
                }
                
                ._label{
                    &.--data{
                        //font-weight: 400;
                        font-size: 1.3rem;
                        text-align: start;
                        color: $text-color--primary;
                    }
                    &.--data2{
                        //font-weight: 400;
                        font-size: 1.4rem;
                        color: $text-color--primary;
                    }
                }
            }
        }
    }
    &.payfail-modal{
        .fail-back{
            padding-top: 1em;
            text-align: center;
            .fail-icon{
                border-radius: 14px;
                background: rgba(255, 255, 255, 0.6);
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            }
        }
        .fail-title{
            padding-top: 0.8em;
            font-size: 1.4em;
            text-align: center;
            color: $text-color--primary
        }
        .fail-span{
            padding-top: 0.2em;
            padding-bottom: 1em;
            font-size: 1em;
            text-align: center;
            color: #707070;
        }
        .fail-btn{
            width: 40%;
            margin-left: 30%;
            margin-top: 3em;
            margin-bottom: 4em;
            font-size: 1em;
            display: flex;
            justify-content: center;
            color: #FFFFFF;
            background-color: $color-primary;
            box-shadow: 0px 16px 25px rgba(208, 208, 208, 0.6);
        }
        .fail-btn:hover{
            width: 40%;
            margin-left: 30%;
            margin-top: 3em;
            margin-bottom: 4em;
            font-size: 1em;
            display: flex;
            justify-content: center;
            border: 0.1em solid #707070;
            color: #707070;
            background-color: rgba(211, 211, 211, 0.4);
            box-shadow: 0px 16px 25px rgba(208, 208, 208, 0.6);
        }
    }
    &.purchase-modal{
        .purchase-container{
            padding: 0em;
            .listing-div{
                height: 5em;
                margin: 1em 0em;
                align-items: center;
                border-bottom: 0.05rem solid  #BBB6B6;
                .col-center{
                    text-align: center;
                }
                ._label{
                    &.--name{
                        // //font-weight: 500;
                        font-size: 1.4rem;
                        color: $text-color--primary;
                    }
                    &.--date{
                        font-size: 1.1rem;
                        // //font-weight: 400;
                        color: $text-color--secondary;
                    }
                    &.--order_no{
                        font-size: 1.1rem;
                        // //font-weight: 400;
                        color: $text-color--secondary;
                    }
                    &.--ticket{
                        // //font-weight: 500;
                        font-size: 1.2rem;
                        color: $text-color--primary;
                    }
                    &.--no{
                        font-size: 1rem;
                        // //font-weight: 400;
                        color: $text-color--secondary;
                    }
                    &.--price{
                        // //font-weight: 400;
                        font-size: 1.4rem;
                        color: $text-color--secondary;
                    }
                }
            }
        }
    }
    &.claim-modal{
        .claim-container{
            .listing-div{
                height: em;
                margin: 1em 0em;
                align-items: center;
                position: relative;
                border-bottom: 0.05rem solid  #BBB6B6;
                ._label{
                    &.--name{
                        // //font-weight: 500;
                        font-size: 1.4rem;
                        color: $text-color--primary;
                    }
                    &.--date{
                        font-size: 1.1rem;
                        // //font-weight: 400;
                        color: $text-color--secondary;
                    }
                    &.--order_no{
                        font-size: 1.1rem;
                        // //font-weight: 400;
                        color: $text-color--secondary;
                    }
                    &.--ticket{
                        // //font-weight: 500;
                        font-size: 1.2rem;
                        text-align: center;
                        color: $text-color--primary;
                    }
                    &.--no{
                        font-size: 1rem;
                        // //font-weight: 400;
                        text-align: center;

                        color: $text-color--secondary;
                    }
                   
                }
                  .col-sticker{
                        text-align: center;
                        font-size: 1.5rem;
                        border-radius: 1.3em;
                        color: #E26368;
                        border: 1px solid #E26368;
                        background: #F3F4F5;
                    }
                .sticker{
                    top: 0;
                    position: absolute;
                    .col-sticker{
                        text-align: center;
                        font-size: 0.9rem;
                        border-radius: 0.1em;
                        border: 0.05em solid black;
                        background: $color-secondary;
                    }
                }
            }
        }
    }
    &.cashback-modal{
        .cashback-container{
            padding: 1em;
            margin: 1em 0em;
            margin-left: auto;
            margin-right: auto;
            border-radius: 1.3em;
            background: #FFFFFF;
            box-shadow: 0px 16px 25px rgba(208, 208, 208, 0.6);
            .title-text{
                margin: 1em 4em 1em 4em;
                border-bottom: 0.1rem solid #888DAA;
            }
            .detail-title{
                margin: 1.5em 3.5em;
                border-radius: 0.5em;
                border: 0.2rem solid $text-color--primary;
            }
            .withdraw-amount{
                align-items: center;
                .amount-col{
                    display: flex;
                    justify-content: end;
                    input{
                        width: 5em;
                        border: none;
                        // //font-weight: 350;
                        font-size: 2.4rem;
                        color: $text-color--secondary;  
                    }
                    input:focus{
                        outline: none;
                    }

                }
            }
            ._label{
                display: flex;
                justify-content: center;
                &.--title{
                    // //font-weight: 400;
                    font-size: 1.4rem;
                    text-align: center;
                    color: $text-color--secondary;  
                }
                &.--price{
                    // //font-weight: 350;
                    font-size: 2.4rem;
                    text-align: center;
                    color: $text-color--secondary;  
                }
                &.--detail{
                    color: #707070;  
                    // //font-weight: 350;
                    font-size: 1.6rem;
                    text-align: center;
                }
            }
            ._span{
                &.--icon{
                    // //font-weight: 325;
                    font-size: 1.6rem;
                    color: $text-color--secondary; 
                    margin-top: auto;
                    margin-bottom: auto;
                }
                &.--price{
                    // //font-weight: 350;
                    font-size: 2.4rem;
                    text-align: center;
                    color: $text-color--secondary;  
                }
                &.--must{
                    color:#EA3943;  
                }
                &.--rm{
                    color:#303345; 
                    font-size: 3rem; 
                    // //font-weight: 400;
                }
            }
            ._p{
                &.--text{
                    // //font-weight: 400;
                    font-size: 1.2rem;
                    color: $text-color--secondary;
                }
            }
        }
        .listing-header{
            align-items: center;
            .tag{
                border-left: 0.3em solid $color-primary;
                border-radius: 0.3em;
            }
            ._label{
                &.--listing{
                    font-size: 2rem;
                    // //font-weight: 600;
                    color: #303345;
                }
                &.--text{
                    display: flex;
                    // //font-weight: 400;
                    color: #666D76;
                    font-size: 1.2rem;
                    justify-content: end;
                }
            }
        }
        .listing-container::-webkit-scrollbar {
            display: none !important;
          }
        .listing-container{
            //overflow: auto;
            // height: 30em !important;
            padding: 0em 1em;
            .listing{
                height: 4em;
                align-items: center;
                border-bottom: 0.05rem  solid #BBB6B6;
            }
            ._label{
                &.--name{
                    // //font-weight: 600;
                    font-size: 1.2rem;
                    color: #3A2E2E;
                }
                &.--date{
                    // //font-weight: 325;
                    font-size: 1rem;
                    color: #3A2E2E;
                }
                &.--price{
                    // //font-weight: 600;
                    font-size: 1.4rem;
                    text-align: end;
                    // color: #3A2E2E;
                    font-family: "roboto-medium";
                }
                &.--price2{
                    // //font-weight: 600;
                    font-size: 1.4rem;
                    text-align: end;
                    color: #E26368;
                    font-family: "roboto-medium";

                    // color: rgb(243, 27, 27);
                }
            }
        }
    }
    &.change-password-modal{
        .change-password-container {
            padding: 1em;
            .btn-row{
                margin-top: 1em;
            }
        }
    }
    &.account-modal{
        .account-container {
            padding: 1em;
            ._label{
                &.--noted{
                    margin-top: 1em;
                    color: #83829A;
                    font-size: 1.2rem;
                    line-height: 120%;
                }
            }
            .btn-row{
                margin-top: 1em;
            }
        }
    }
    &.change-phone-modal{
        .change-phone-container {
            padding: 1em;
            .btn-row{
                margin: 1em 0em -0.5em 0em;
            }
        }
    }
    &.information-modal{
        .modal-content {
            min-height: 0em !important;
        }
        ._label{
            &.--title{
                // //font-weight: bold;
                font-size: 1.75rem;
                color: $text-color--primary;
            }
            &.--text{
                padding: 0.3em;
                // //font-weight: 400;
                font-size: 1.5rem;
                color: $text-color--secondary;
            }
            &.--stamps{
                font-size: 1.8rem;
                color: #ED1C24;
            }
            &.--congratulation{
                color: #ED1C24;
                font-size: 3.5rem;
                font-weight: 600;
            }
            &.--price{
                color: #3A2E2E;
                font-size: 2rem;
                font-weight: 550;
            }
        }
        ._span{
            &.--stamps{
                // font-weight: 600;
                color: $color-primary;
            }
        }
        .header{
            display:flex ;
            justify-content: center;
        }
        .icon-col{
            text-align: end;
        }
        .information{
            margin-top: 1em;
        }
    }
    &.congratulation-modal{
        .modal-body{
            max-width: 500px; ;
            max-height: 673.13px;
        }
        .modal-content{
            // max-height: 590.13px;
            // height: 690.13px;
            height: 100%;
            width: 100%;
            box-shadow: none;
            // background : rgba(0, 0, 0, 0);
            background : #f8f8f8cb
        }
        @media (min-width: 991px) {
            .modal-content{
                max-height: 670.13px;
                height: 690.13px;
                // height: 100%;
                // width: 100%;
                box-shadow: none;
                // background : rgba(0, 0, 0, 0);
                background : #f8f8f8cb
            }
        }
        .modal-dialog{
            max-width: 500px; ;
            max-height: 653.13px;
            height: 100%;
            width: 100%;
        }
    }
    &.remove-modal{
        .modal-content {
            min-height: 0 !important;
        }
        .remove-title{
            padding-top: 0.8em;
            font-size: 1.4em;
            text-align: center;
            color: $text-color--primary
        }
        .remove-span{
            padding: 0.8em;
            font-size: 1em;
            text-align: center;
            color: #707070;
        }
        .header{
            display:flex ;
            justify-content: center;
        }
        .icon-col{
            text-align: end;
        }
        .information{
            margin-top: 2em;
            .btn-row{
                align-items: center;
                justify-content: center;
                .home_button{
                    width: 35%;
                    height: 2.7em;
                    font-size: 0.9em;
                    border-radius: 0.3em;
                    background: $color-primary;
                    box-shadow: 0px 16px 25px rgba(208, 208, 208, 0.6);
                }
                .remove_button{
                    width: 35%;
                    height: 2.7em;
                    font-size: 0.9em;
                    border-radius: 0.3em;
                    background: #df1d1d;
                    box-shadow: 0px 16px 25px rgba(208, 208, 208, 0.6);
                }
                .remove_button:hover{
                    width: 35%;
                    height: 2.7em;
                    // //font-weight: 500;
                    font-size: 0.9em;
                    border-radius: 0.3em;
                    border: 0.12em solid #707070;
                    color: $text-color--primary;
                    background-color: rgba(211, 211, 211, 0.4);
                    box-shadow: 0px 16px 25px rgba(208, 208, 208, 0.6);
                }
                .fail-btn{
                    width: 35%;
                    height: 2.7em;
                    font-size: 0.9em;
                    border-radius: 0.3em;
                    background-color: $color-primary;
                    box-shadow: 0px 16px 25px rgba(208, 208, 208, 0.6);
                }
                .fail-btn:hover{
                    width: 35%;
                    height: 2.7em;
                    // //font-weight: 500;
                    font-size: 0.9em;
                    border-radius: 0.3em;
                    border: 0.12em solid #707070;
                    color: $text-color--primary;
                    background-color: rgba(211, 211, 211, 0.4);
                    box-shadow: 0px 16px 25px rgba(208, 208, 208, 0.6);
                }
            }
        }
    }
  
}

.infinite-scroll-component::-webkit-scrollbar{
    display: none !important;
}
.cart-modal-2{
    [data-rsbs-scroll]::-webkit-scrollbar {
        display: none !important;
    }
    [data-rsbs-overlay] {
        z-index: 2000;
    }
    [data-rsbs-backdrop] {
        z-index: 1999;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .food-title{
        ._label{
            &.--title{
                font-family: "roboto-medium";
                font-size: 2.2rem;
                color: $text-color--primary;
            }
            &.--price{
                display: flex;
                font-family: "roboto-medium";
                font-size: 2.2rem;
                justify-content: end;
                color: $text-color--primary;
            }
            &.--text{
                display: flex;
                font-family: "roboto-medium";
                font-size: 1.4rem;
                justify-content: end;
                color: $text-color--secondary;
            }
           
        }
    }
    .food-container{
        margin: 1em 0em;
        .food-listing{
            align-items: center;
           
            ._label{
                &.--food-name{
                    // //font-weight: 400;
                    word-break: break-all;
                    font-size: 1.4rem;
                    color: $text-color--primary;
                }
                &.--quantity{
                    // //font-weight: 700;
                    color: #9091A0;
                    border: 0.1em solid;
                    font-size: 1.6rem;
                    border-radius: 50%;
                    padding: 0em 0.6em;
                }
                &.--addon{
                    // //font-weight: 400;
                    font-size: 1.2rem;
                    color: $text-color--primary;
                }
                &.--price{
                    text-align: end;
                    // //font-weight: 600;
                    font-size: 1.4rem;
                    color: $text-color--primary;
                }
            }
            .end{
                text-align: center;
            }
        }
    }
    .button{
        .btn-primary{
            margin-top: 1em;
            border-radius: 0.8em;
            background: $btn-background-color--success;
        }
    }
}
.update-modal{
    .modal-content {
        min-height: 0 !important;
    }
    padding: 0.5em;
    .update-body{
    border-radius: 0.3em;
    border : 0.1em solid rgb(44, 41, 41) ;

        ._label{
          
            &.--title{
                font-size: 1.8rem;
                // font-weight: 700;
                color:$color-primary;
            }
            &.--cancel{
                font-size: 1.5rem;
                // font-weight: 700;
                color: rgb(55, 47, 47);
            }
            &.--ok{
                font-size: 1.5rem;
                // font-weight: 700;
                color:$color-primary;
            }
        }
        ._p{
            font-size: 1.4rem;
            color: $text-color--primary;
        }
    }
}
.alcoholic-modal-2{
    [data-rsbs-scroll]::-webkit-scrollbar {
        display: none !important;
    }
    [data-rsbs-overlay] {
        z-index: 2000;
    }
    [data-rsbs-backdrop] {
        z-index: 1999;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .food-title{
        ._label{
            &.--title{
                // //font-weight: 500;
                font-size: 2.2rem;
                color: $text-color--primary;
            }
            &.--quantity{
                display: flex;
                // //font-weight: 500;
                font-size: 2.4rem;
                justify-content: end;
                color: $text-color--primary;
            }
            &.--text{
                display: flex;
                // //font-weight: 500;
                font-size: 1.4rem;
                justify-content: end;
                color: $text-color--secondary;
            }
           
        }
    }
        .count{
            margin-top: 1.5em;
            justify-content:center ;
        }
        .btn-alcoholic{
            margin-top: 1em;
            justify-content:center ;
        }
    }
.bank-container{
    padding: 0em 2em !important;
    .skeleton_bank{
        padding-top: 10px;
        margin-top: 0.8em;
        align-items: center;
        justify-content:center ;
     }
     .col_bank{
        padding-top: 10px;
        margin-top: 0.8em;
     }
    ._label{
        &.--icon{
            font-size: 2rem;
            // //font-weight: 500;
            color: $text-color--primary;
        }
        &.--payment{
            font-size: 2rem;
            font-family: "roboto-medium";
            // //font-weight: 500;
            color: $text-color--primary;
        }
    }
    // .div-loader{
    //     //height: 100%;
    //     //width: ;
    //     //position: fixed;
    //     //top: 0;
    //     //z-index: 9999;
    //     //left: 0;
    //     //background-color: rgb(0,0,0);
    //     //background-color: rgba(0,0,0, 0.3);
    //     //overflow-x: hidden;
    //     //transition: 0.5s;
        
    //     justify-content: center !important; 
    //     align-items: center !important;

    //     //width: 100%;
    //     //height: 100vh;
    //     //padding-top: 100px;
    //     display: flex;
    // }
    .payment-border{
        height: 4em;
        margin: 1em 0em;
        align-items: center;
        border-radius: 0.625em;
        border: 0.2em solid #E2E2E2;
        &.active{
        border: 0.2em solid $color-primary;
        }
        .radio_button{
            display: flex;
            justify-content: center;
            .form-check-input[type=radio]{
                display: flex;
                width: 1.6rem;
                height: 1.6rem;
                align-items: center;
            }
            .form-check-input:checked {
                border-color: $color-primary;
            }
            .form-check-input[type=radio]:checked:after{
                width: 1rem;
                height: 1rem;
                background: $color-primary;
                border-color: $color-primary;
            }
        }
        
        .method_bg{
            align-items: center;
            display: flex;
            margin: auto;
            // height: 34px;
            width: 3em;
            .method_img{
                width: 100%;
                height: 100%;
                // object-fit: cover;
            }
        }

        .bank_bg{
            align-items: center;
            display: flex;
            margin: auto;
            width: 2em;
            .bank_image{
                width: 100%;
                height: 100%;
            }
        }

        .first_bank_bg{
            width: 2em;
            align-items: center;
            display: flex;
            margin: auto;
            .first_bank_image{
                width: 100%;
                height: 100%;
            }
        }

        // .bank_image{
        //     width: 60%;
        // }

        // @media all and (max-width: 501px) {
        //     .bank_image{
        //         width: 150%;
        //     }
        // }

        ._label{
            &.--bank{
                font-family: "roboto-medium";
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 1.4rem;
                color: $text-color--primary;
            }
            &.--drop{
                padding-left: 30px;
            }
            .drop{
                color: #c7c6c6;
                &.active{
                    color: $color-primary;
                }
            }
        }
    }
}

.redeem-modal{
    .redeem-container{
        ._label{
            &.--title{
                font-size: 2rem;
                // font-weight: 600;
                color: #3A2E2E;
            }
            &.--text{
                font-size: 1.4rem;
                // font-weight: 600;
                color:  #828282;
                ;
            }
            &.--name{
                font-size: 1.6rem;
                // font-weight: 600;
                color:  #3A2E2E;
                
            }
            &.--stemps{
                font-size: 1.2rem;
                // font-weight: 600;
                color: rgba(15, 15, 15, 0.5);
                
            }
          
        }
        .stamps-row{
            align-items: center;
            margin: 0.5em 0em;
            padding: 6px 12px;
            border-radius: 1rem;
            border: 2px solid #F3F4F5;
            &.active{
             background: #FFFAFA !important;
            border: 2px solid $color-primary !important ;
            }
        }
        .pay-button{
            margin-top: 2em;
            .button{
                width: 38em;
                .btn-primary{
                    height: 2.9em;
                    border-radius: 0.75em;
                    text-transform:capitalize;
                    color: $btn-color--success;
                    background-color:$btn-background-color--success;
                }
            }
        }
    }
}

//header
.cart-modal-header{
    .header{
        margin-top: 1em;
        padding: 0em 0.8em;
    }
    ._label{
        &.--order{
            font-family: "roboto-medium";
            font-size: 1.8rem;
        } 
    }
    .icon{
        font-size: 2.4rem;
    }
    
}
.modal-header-container{
    background: #FFFFFF;
    padding: 1.5em 0em 1em 0em;
    border-radius: 0px 0px 18px 18px;
    margin-top: -0.5em !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    .icon-back{
        text-align: center;
    }
    .icon{
        border-radius: 14px;
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }
    ._label{
        color: #303345;
        font-family: "roboto-medium";
        font-size: 1.8rem;
    }
}



//home page
.home-div{
    max-height: 100%;
    .home-header{
        width:  400px;
        height: 160px;
            .banner-image{
                width: 100%;
                height:100%;
                object-fit: cover;
            }
    }
    .home-container{
        margin-top: 0.5em;
        border-radius: 0.6em;
        background: #FFFFFF;
        border: 0.3rem solid rgb(241, 239, 239);
        ._label{
            font-size: 2rem;
            margin-top: 1em;
            // //font-weight: 700;
            margin-bottom: 0.7em;
        }
        ._p{
            color: #373A2E;
            font-size: 1.35rem;
            margin-bottom: 01em;
        }
    }
    ._label{
        &.--text{
            color: #707070;
            font-family: "roboto-medium";
            font-size: 1.1rem;
        } 
        &.--text:hover{
            color: #E26368;
            font-family: "roboto-medium";
            font-size: 1.1rem;
        }  
        
    }
    .logo-div{
        height: 174px;
        width: 174px;
        margin: auto;
        .logo-image{
            height: 100%;
            width: 100%;
        }
    }
    .box-div{
        margin-top: -1.5em;
        border-radius: 24px;
        padding: 25px 25px;
        align-items: center;
        box-shadow: 0px 16px 25px 0px rgba(208, 208, 208, 0.60);
        ._label{
            &.--big{
                color: #83829A;
                font-size: 2rem;
            }
            &.--samll{
                color: #707070;
                font-size: 1.4rem;
            }
        }
    }
    .footer-div{
        margin-top: 50px;
        background-position: top;
        padding-top: 78px;
        &.desktop{
            // background: #ee1d23;
            width: 100%;
            border-radius: 500px 500px 0px 0px;
        }
        ._label{
            &.--scan{
                color: #FFDC01;
                font-size: 3.2rem;
                font-family: "cooper-black";
            }
        }
        span {
            color: #FFF;
        }
        .lottie-div{
            width: 117px;
            height: 117px;
            margin: auto;
        }

    }
}


.element-container{
    margin-top: 1.25em;
    .menu_search{
     width: 96%;
     height: 2.5em;
     z-index: 1050;
     margin-left: auto;
     margin-right: auto;
     border-radius: 2em;
     align-items: center;
     border-radius: 1.25em;
     background: #FFFFFF;
     backdrop-filter: blur(5px);
     box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
     input{
         border: none;
         outline: none;
         padding-left: 1em;
         font-size: 1.6rem;
         font-family:system-ui;
         background: transparent !important;
     }
     .icon{
         color: $color-primary;
     }
    } 
    .menu_tab{
     z-index: 1999;
     margin-top: 1.1em;
     margin-bottom: 1.3em;
      &.none{
         display: none;
     }
     .tab-view{
         text-align: center;
         .food-tab{
            //  //font-weight: 500;
             font-size: 1.6rem;
             border:none !important;
             justify-content: center;
             color: $text-color--secondary !important;
             background: rgba(0, 0, 0, 0) !important;
             &.active{
                 box-shadow: none;
                 color: $color-primary !important;
                 border-bottom: 0.3rem solid #FFE7E7 !important;
             }
         }
     }
     .p-tabview-nav{
         border: none;
         background: rgba(0, 0, 0, 0);
     }
     .p-unselectable-text{
         width: 20%;
         background:rgba(0, 0, 0, 0);
      }
      .p-tabview-nav-link{
        //  //font-weight: 500;
         font-size: 1.57rem;
         border:none !important;
         justify-content: center;
         color: $text-color--secondary !important;
         background: rgba(0, 0, 0, 0) !important;
      }
      .p-highlight{
         .p-tabview-nav-link{
              color: $color-primary !important;
          }
      }
      .p-tabview-nav-link:not(.p-disabled):focus{
         box-shadow: none;
         color: $color-primary !important;
      }
       .p-tabview-panels{
         background: rgba(0, 0, 0, 0) !important;
      }
      .p-tabview-ink-bar{
         background-color: $color-primary !important;
      }
    }
    .skeleton_tab{
      margin-top: 1.9em;
     margin-bottom: 1.5em;
    }
    .skeleton_row{
      margin-top: 1.3em;
     margin-bottom: 1.5em;
    .skeleton_col{
        display: flex;
      align-items: center;
     justify-content: center;
    }
    }
    .menu-listing{
        margin-top: 1.1em;
        margin-bottom: 2em;
        background: rgba(0, 0, 0, 0);
        .listing-container{
         -webkit-animation: slide-top 15s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	     animation: slide-top 15s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
         width: 10em;
         height: 15em;
         position: relative;
         background: #FFFFFF;
         border-radius: 1.75em;
         box-shadow: 0px 10px 40px rgba(101, 101, 101, 0.2);
     }
     .image{
         top: -2em;
         position: relative;
         justify-content: center;
         .food-image{
             width: 9em;
             height: 8.2em;
             object-fit: cover;
             position: absolute;
             border-radius: 50%;
             justify-content: center;
             filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.25));
         }
     }
     .text-label{
         padding-top: 7.5em;
             ._label{
             &.--title{
                 height: 5em;
                 overflow: hidden;
                 font-size: 1.4rem;
                 font-style: normal;
                 color: $text-color--primary;
             }
             &.--price{
                 margin-top: 0.85em;
                //  //font-weight: 700;
                 font-size: 1.3rem;
                 text-align: center;
                 font-style: normal;
                 color: $text-color--primary;
             }
             &.--code{
                 font-size: 0.75em;
                //  //font-weight: 500;
                 margin-left: -0.8em;
                 background: #dfdfdf;
                 height: 1.5em;
                 width: 3.5em;
                 border-radius: 0.3em;
                 margin-top: 1em;
             }  
         }
     }
         ._label{
             &.--title{
                    //  //font-weight: 900;
                     font-size: 1.9rem;
                     font-style: normal;
                     color: $text-color--primary;
             }
            }
    }
    .message{
     height: 7em;
     text-align: center;
     align-items: center;
     justify-content: center;
     ._label{
         &.--message{
             font-size: 2rem;
             color: $text-color--secondary;
         }
     }
    }
    .category-row {
        width: 100%;
        overflow-x: auto; /* 将 overflow-x 设为 auto */
        white-space: nowrap; /* 防止文本换行 */
        scroll-snap-type: x mandatory;
        margin: auto;
        overflow-y: hidden;
        margin-top: 1.3em;
        margin-bottom: 0.5em;
      }
      
      .category-div {
        display: inline-block;
        width: 50%; /* 使每个 category-div 至少占据 50% 的宽度 */
        text-align: center;
        scroll-snap-align: center;
        label{
            color: #83829A;
            font-size: 1.6rem;
        }
        .active{
            color: #e26368;
            font-family: "roboto-medium";
        }
        }
        .category-row::-webkit-scrollbar{
          display: none !important;
        }
    }


//menu page
.menu-page-container{
    // overflow: auto;
    // height: 100%;
    .menu-banner{
        width: 100%;
        height: 0.7em;
        margin-top: 7em !important;
        margin-left: auto !important;
        margin-right: auto !important;
        .banner_image{
            height: 10.625em;
            object-fit: cover;
            border-radius: 1em;
        }
    }
    .menu-location-card{
        .location_card{
            margin-top: 7em;
            height: 5.44em;
            padding: 0.7em 0.75em;
            width: 21em !important;
            border-radius: 0.875em;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(7.5px);
            ._label{
             &.--title{
                //  //font-weight: 600;
                font-family: "roboto-medium";
                 font-size: 2.4rem;
                 overflow: hidden;
                 color: #3A2E2E;
             }
             &.--desc{
                 font-size: 1.3rem;
                 color: $color-primary;
                //  overflow:hidden;
                overflow-wrap: break-word;
             }
             &.--detail{
                 font-size: 1.2rem;
                 color: $text-color--secondary;
             }
            }
            .icon{
             color: $color-primary;
         }
        }
    }
    .menu-food-listing{
        margin-top: 1.25em;
       .menu_search{
        width: 96%;
        height: 2.5em;
        z-index: 1050;
        margin-left: auto;
        margin-right: auto;
        border-radius: 2em;
        align-items: center;
        border-radius: 1.25em;
        background: #FFFFFF;
        backdrop-filter: blur(5px);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        &.none{
            display: none;
        }
        input{
            border: none;
            outline: none;
            padding-left: 1em;
            font-size: 1.6rem;
            font-family:system-ui;
            background: transparent !important;
        }
        .icon{
            color: $color-primary;
        }
       } 
       .menu_tab{
        top: 19%;
        z-index: 1999;
        position :sticky;
        margin-top: 1.1em;
        margin-bottom: 1.3em;
         &.none{
            display: none;
        }
        .tab-view{
            text-align: center;
            .food-tab{
                // //font-weight: 500;
                font-size: 1.6rem;
                border:none !important;
                justify-content: center;
                color: $text-color--secondary !important;
                background: rgba(0, 0, 0, 0) !important;
                &.active{
                    box-shadow: none;
                    color: $color-primary !important;
                    border-bottom: 0.3rem solid #FFE7E7 !important;
                }
            }
        }
        .p-tabview-nav{
            border: none;
            background: rgba(0, 0, 0, 0);
        }
        .p-unselectable-text{
            width: 20%;
            background:rgba(0, 0, 0, 0);
         }
         .p-tabview-nav-link{
            // //font-weight: 500;
            font-size: 1.57rem;
            border:none !important;
            justify-content: center;
            color: $text-color--secondary !important;
            background: rgba(0, 0, 0, 0) !important;
         }
         .p-highlight{
            .p-tabview-nav-link{
                 color: $color-primary !important;
             }
         }
         .p-tabview-nav-link:not(.p-disabled):focus{
            box-shadow: none;
            color: $color-primary !important;
         }
          .p-tabview-panels{
            background: rgba(0, 0, 0, 0) !important;
         }
         .p-tabview-ink-bar{
            background-color: $color-primary !important;
         }
       }
       .skeleton_tab{
         margin-top: 1.9em;
        margin-bottom: 1.5em;
       }
       .skeleton_row{
         margin-top: 1.3em;
        margin-bottom: 1.5em;
       .skeleton_col{
           display: flex;
         align-items: center;
        justify-content: center;
       }
       }
       .menu-listing{
           margin-top: 1.1em;
           margin-bottom: 2em;
           background: rgba(0, 0, 0, 0);
           animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
           .listing-container{
            width: 10em;
            height: 11.5em;
            position: relative;
            background: #FFFFFF;
            border-radius: 1.75em;
            box-shadow: 0px 10px 40px rgba(101, 101, 101, 0.2);
        }
        .image{
            top: -2em;
            position: relative;
            justify-content: center;
           
            .food-image{
                width: 9em;
                height: 6.8em;
                object-fit: cover;
                position: absolute;
                border-radius: 1em;
                justify-content: center;
                filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.25));
            }
            .sold_out{
                display: flex;
                height: 6.8em;
                color: #FFFFFF;
                font-size: 1.8rem;
                position: absolute;
                border-radius: 1em;
                text-align: center;
                align-items: center;
                justify-content: center;
                backdrop-filter: blur(0.2px);
                background: linear-gradient(0deg, rgba(116, 116, 116, 0.7), rgba(116, 116, 116, 0.7))    
            }
        }

        .price-row{
            align-items:center ;
            justify-content: center;
          }

        .text-label{
            padding-top: 5.5em;
                ._label{
                &.--title{
                    font-family: "roboto-medium";
                    height: 3.2em;
                    overflow: hidden;
                    font-size: 1.4rem;
                    font-style: normal;
                    color: $text-color--primary;
                    // overflow-wrap: break-word;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -webkit-box !important;
                }
                &.--price{
                    margin-top: 0.85em;
                    font-family: "roboto-medium";
                    font-size: 1.3rem;
                    text-align: center;
                    font-style: normal;
                    color: $text-color--primary;
                }
                &.--code{
                    width: 3.5em;
                    height: 1.5em;
                    margin-top: 1em;
                    // //font-weight: 500;
                    font-size: 0.65em;
                    margin-left: -0.8em;
                    border-radius: 0.3em;
                    background: #dfdfdf;
                }  
            }
        }
            ._label{
                &.--title{
                        // //font-weight: 900;
                        font-family: "roboto-medium";
                        font-size: 1.9rem;
                        font-style: normal;
                        color: $text-color--primary;
                }
               }
       }
       .message{
        height: 7em;
        text-align: center;
        align-items: center;
        justify-content: center;
        ._label{
            &.--message{
                font-size: 2rem;
                color: $text-color--secondary;
            }
        }
       }
    }
}

//alcoholic page
.alcoholic-page-container{
    .alcoholic-banner{
        width: 100%;
        height: 0.7em;
        margin-top: 7em !important;
        margin-left: auto !important;
        margin-right: auto !important;
        .banner_image{
            height: 10.625em;
            object-fit: cover;
            border-radius: 1em;
        }
    }
    .alcoholic-location-card{
        .location_card{
              margin-top: 7em;
            height: 5.44em;
            padding: 0.7em 0.75em;
            width: 21em !important;
            border-radius: 0.875em;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(7.5px);
            ._label{
             &.--title{
                // //font-weight: 600;
                font-family: "roboto-medium";
                 font-size: 2.4rem;
                 overflow: hidden;

             }
             &.--desc{
                 font-size: 1.4rem;
                 color: $color-primary;
                 overflow: hidden;

             }
             &.--detail{
                 font-size: 1.2rem;
                 color: $text-color--secondary;
             }
            }
            .icon{
             color: $color-primary;
         }
        }
    }
    .alcoholic-ticket{
        margin-top: 1.2em;
        .ticket-component{
            padding: 0em 1.2em;
            .ticket-title{
                display: flex;
                align-items: center;
                ._label{
                    &.--title{
                        font-family: "roboto-medium";
                        font-size: 1.9rem;
                        color: $text-color--primary;
                    }
                }
                .icon{
                    color: $text-color--secondary;
                    font-size: 1.3em;
                }
            }
            .ticket-text{
                ._label{
                    &.--text{
                        font-family: "roboto-medium";
                        font-size: 1.4rem;
                        color: $text-color--secondary;
                    }
                }
                
            }
            .ticket-claim{
                padding: 0.5em;
                border-radius: 0.75em;
                background: $background-color--third;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                ._label{
                    text-align: center;
                    &.--name{
                        // //font-weight: 400;
                        font-size: 1.4rem;
                        color: $text-color--primary;
                    }
                    &.--quantity{
                        // //font-weight: 400;
                        font-size: 1.2rem;
                        color: $text-color--secondary;
                    }
                    &.--date{
                        // //font-weight: 400;
                        font-size: 1.2rem;
                        color: $text-color--secondary;
                    }
                }
                .ticket-list{
                    align-items: center;
                    margin: 0.3125em 0em;
                    justify-content: center;
                    .image{
                        margin-left: 0em;
                        height: 3.375em;
                        .food-image{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 0.375em;
                            border: 0.1rem solid #C3C4CD;
                        }
                    }
                    .claim_button{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .btn-primary{
                            height: 100%;
                            width: 100%;
                            font-size: 1.35rem;
                            display: flex;
                            box-shadow: none;
                            color: #FFFFFF;
                            align-items: center;
                            border-radius: 0.375em;
                            justify-content: center;
                            text-transform:capitalize;
                            background: #9091A0;
                        }
                    }
                }
            }
        }
    }
    .alcoholic-food-listing{
        margin-top: 1.25em;
        ._label{
            &.--title{
                    // //font-weight: 900;
                    font-size: 1.9rem;
                    font-style: normal;
                    color: $text-color--primary;
            }
           }
       .alcoholic_search{
        width: 96%;
        height: 2.5em;
        z-index: 1050;
        margin-left: auto;
        margin-right: auto;
        border-radius: 2em;
        align-items: center;
        border-radius: 1.25em;
        background: #FFFFFF;
        backdrop-filter: blur(5px);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        input{
            padding-left: 1em;
            border: none;
            outline: none;
            font-family:system-ui;
        }
        .icon{
            color: $color-primary;
        }
       } 
       .skeleton_tab{
        margin-top: 1.9em;
       margin-bottom: 1.5em;
      }
      .skeleton_row{
        margin-top: 1.3em;
       margin-bottom: 1.5em;
      .skeleton_col{
          display: flex;
        align-items: center;
       justify-content: center;
      }
      }
       .alcoholic_tab{
        top: 19%;
        z-index: 1999;
        position :sticky;
        margin-top: 1.1em;
        margin-bottom: 1.3em;
        .tab-view{
            text-align: center;
            .food-tab{
                // //font-weight: 500;
                font-size: 1.6rem;
                border:none !important;
                justify-content: center;
                color: $text-color--secondary !important;
                background: rgba(0, 0, 0, 0) !important;
                &.active{
                    box-shadow: none;
                    color: $color-primary !important;
                    border-bottom: 0.3rem solid #FFE7E7 !important;
                }
            }
        }
        .p-tabview-nav{
            border: none;
            background: rgba(0, 0, 0, 0);
        }
        .p-unselectable-text{
            width: 20%;
            background: rgba(0, 0, 0, 0);
         }
         .p-tabview-nav-link{
            font-size: 1.57rem;
             border:none !important;
             justify-content: center;
             color: $text-color--secondary !important;
             background: rgba(0, 0, 0, 0) !important;
         }
         .p-tabview-nav-link{
            font-size: 1.57rem;
             border:none !important;
             justify-content: center;
            //  color: $text-color--secondary !important;
             background: rgba(0, 0, 0, 0) !important;
         }
         .p-highlight{
            .p-tabview-nav-link{
                 color: $color-primary !important;
             }
         }
         .p-tabview-nav-link:not(.p-disabled):focus{
            box-shadow: none;
         }
          .p-tabview-panels{
            background: rgba(0, 0, 0, 0) !important;
         }
         .p-tabview-ink-bar{
            background-color: $color-primary !important;
         }
       }
       .alcoholic-listing{
           margin-top: 2em;
           margin-bottom: 2em;
           animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
           .listing-container{
            width: 10em;
            height: 11.5em;
            position: relative;
            background: #FFFFFF;
            border-radius: 1.75em;
            box-shadow: 0px 10px 40px rgba(101, 101, 101, 0.2);
        }
        .image{
            top: -2em;
            position: relative;
            justify-content: center;
            .food-image{
                width: 9em;
                height: 6.8em;
                object-fit: cover;
                position: absolute;
                border-radius: 1em;
                justify-content: center;
                filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.25));
            }
            .sold_out{
                display: flex;
                height: 6.8em;
                color: #FFFFFF;
                font-size: 1.8rem;
                position: absolute;
                border-radius: 1em;
                text-align: center;
                align-items: center;
                justify-content: center;
                backdrop-filter: blur(0.2px);
                background: linear-gradient(0deg, rgba(116, 116, 116, 0.7), rgba(116, 116, 116, 0.7))    
            }
        }
        .price-row{
          align-items:center ;
          justify-content: center;
        }
        .text-label{
            padding-top: 5.5em;
                ._label{
                &.--title{
                    height: 3.2em;
                    font-family:"roboto-medium" ;
                    overflow: hidden;
                    font-size: 1.4rem;
                    font-style: normal;
                    color: $text-color--primary;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -webkit-box !important;
                }
                &.--price{
                    margin-top: 0.85em;
                    font-family:"roboto-medium" ;
                    font-size: 1.3rem;
                    text-align: center;
                    font-style: normal;
                    color: $text-color--primary;
                }
                &.--code{
                    width: 3.5em;
                    height: 1.5em;
                    margin-top: 1em;
                    // //font-weight: 500;
                    font-size: 0.65em;
                    margin-left: -0.8em;
                    border-radius: 0.3em;
                    background: #dfdfdf;
                }    
            }
        }
        ._label{
            &.--title{
                    font-family: "roboto-medium";
                    font-size: 1.9rem;
                    font-style: normal;
                    color: $text-color--primary;
            }
           }
       }
       .message{
        height: 7em;
        text-align: center;
        align-items: center;
        justify-content: center;
        ._label{
            &.--message{
                font-size: 2rem;
                color: $text-color--secondary;
            }
        }
       }
    }
}

//cart page 
.cart-page-container{
    [data-rsbs-scroll]::-webkit-scrollbar {
        display: none !important;
    }
    [data-rsbs-overlay] {
        z-index: 2000;
    }
    [data-rsbs-backdrop] {
        z-index: 1999;
        background-color: rgba(0, 0, 0, 0.1);
    }
    background: #FFFFFF;
    .div-loader-bg{
        position: absolute;
        background: rgba(0,0,0,0.4) !important;
        //padding-top: 100px;
        right: 0;
        z-index: 10000000000;
        top: 0;
        // left: 0;
        overflow-x: hidden;
        // transition: 0.5s;
        
        // justify-content: center !important; 
        // align-items: center !important;

        width: 100%;
        height: 100%;

        // padding-top: 200px;
        // display: flex;
        .div-loader{
            //left: 48.5%;
            //top: 150%;
            //background-color: rgba(0,0,0,0.5);
            padding-top: 20em;
            display: flex !important;
            justify-content: center !important; 
            align-items: center !important;
        }

    }
    .cart-container{
        // height: 46.3em;
        overflow: scroll;
        margin-bottom: 4em;
        .cart-title{
            margin-top: 7em;
            .title-col{
                display: flex;
                align-items: center;
                ._label{
                    &.--title{
                        font-size: 3rem;
                        font-family: "roboto-medium";
                        color:#635151 ;
                    }
                }
    
            }
            ._label{
                &.--text{
                    font-family: "roboto-medium";
                    font-size: 1.4rem;
                    color:$text-color--primary ;
                }
            }
        }
        .image-div{
            height: 67vh;
            display: flex;
            justify-content: center;
            background: #FFFFFF;
            .logo-image{
               margin-top: 5.5em;
               width: 168px;
               height: 168px;
               opacity: 0.3;
               
               @media (max-width: 500px) {
                margin-top: 1.5em;
               }
            }
            .loading-div {
                margin-top: -1em;
                font-size: 20px;
                font-weight: 600;
                font-style: italic;
                letter-spacing: 0.05em;
                color: #6351514f;
                font-family: "roboto-bold";
                // text-transform: uppercase;
            }

            .loading-lottie{
                // margin-top: 5.5em;
                width: 220px;
                margin-bottom: 0em;

                &.--two {
                    width: 100px;
                    margin-top: -1.5em;
                }
            }
        }
        .table-data{
            margin-top: 2.5em;
            text-align: center;
            .table-no{
                display: flex;
                height: 2.43em;
                align-items: center;
                background: #F7F7F7;
                border-radius: 0.75em;
                justify-content: center;
                ._label{
                    &.--text{
                        // //font-weight: 400;
                        font-size: 1.5rem;
                        color: $text-color--secondary;
                    }
                }
            }
        }
        .food-order{
            margin-top: 0.9em;
            ._label{
                &.--title{
                    margin: 1em 0em;
                    font-family: "roboto-medium";
                    font-size: 1.4rem;
                    text-transform: capitalize;
                    color: $text-color--primary;
                }
                &.--addon{
                    font-size: 1.3rem;
                    color: $text-color--primary;
                }
                &.--remark{
                    word-break: break-all;
                    font-size: 1.2rem;
                    color: $text-color--secondary;
                }
            }
            .order-container{
                margin: 0.5em 0em;
                .order-listing{
                    .hidden{
                        overflow: hidden;
                    }
                    ._label{
                        &.--food-name{
                            font-family: "roboto-medium";
                            font-size: 1.4rem;
                            color: $text-color--primary;
                        }
                        &.--price{
                            font-family: "roboto-medium";
                            font-size: 1.4rem;
                            color: $text-color--primary;
                        }
                        &.--code{
                            font-size: 0.75em;
                            font-family: "roboto-medium";
                            background: #dfdfdf;
                            height: 1.5em;
                            width: 3.5em;
                            border-radius: 0.3em;
                            // margin-top: 1em;
                        }  
                       
                    }
                    .food-price{
                        text-align: end;
                    }
                }
                .add-order{
                    ._label{
                        &.--addon{
                            font-size: 1.2rem;
                            color: #83829A;
                        }  
                    }
                    .order-div{
                        margin-top: 0.3em;
                    }
                }
            }
        }
       
        .payment-detail{
            margin-top: 1em;
            .icon{
                display: flex;
                justify-content: end;
            }
            .amount-col{
                text-align: end;
            }
            ._label{
                &.--data{
                    display: flex;
                    // //font-weight: 400;
                    font-size: 1.2rem;
                    align-items: center;
                    color: $text-color--primary;
                }
                &.--data2{
                    // //font-weight: 400;
                    font-size: 1.4rem;
                    color: $text-color--primary;
                }
                &.--v-text{
                    display: flex;
                    // //font-weight: 400;
                    font-size: 1.2rem;
                    align-items: center;
                    color: $text-color--primary;
                }
                &.--decution{
                    font-size: 1.4rem;
                    // font-weight: 600;
                    color: $text-color--primary;
                }
            }
            .voucher{
                margin: 0.5em 0em;
                align-items: center;
                border-radius: 0.6em;
                border:0.15rem solid $text-color--primary;
                &.active{
                    background: $color-secondary;
                    border:0.15rem solid $color-primary;
                }
            }
            .redeem-row{
                ._label{
                    &.--data{
                            font-size: 0.75em;
                            // //font-weight: 500;
                            height: 100%;
                            width: 4.8em;
                            cursor: pointer;
                            color: #83829A;
                            border-radius: 0.3em;
                            background: #F3F4F5;
                    }
                   
                }
                .redeem-btn{
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    padding: 6px 10px;
                    max-width: 109px;
                    max-height: 29px;
                    border-radius: 8px;
                    font-size: 1.4rem;
                    color: #FDFDFF;
                    background: #E26368;
                    border: none;
                    box-shadow: 0px 4px 0px 0px #D9D9D9;
                }
                .redeem-btn:active{
                    transform: translate(0em, 0.05em);
                    box-shadow: 0px 2px 0px 0px #D9D9D9;
                }
            }
            .pay-button{
                margin-top: 2em;
                .button{
                    width: 38em;
                    .btn-primary{
                        height: 2.9em;
                        border-radius: 0.75em;
                        text-transform:capitalize;
                        color: $btn-color--success;
                        background-color:$btn-background-color--success;
                    }
                }
            }
        }
        .error-balance-message{
            margin: 0;
            padding: 0;
            text-align: center;
            margin-top: -0.4em;
            ._label{
                color: red;
                font-size: 1rem;
            }
        }
    }
    .cart-container::-webkit-scrollbar {
        display: none !important;
      }
}

//my order page 
.my-order-page-container{
     [data-rsbs-scroll]::-webkit-scrollbar {
        display: none !important;
    }
    [data-rsbs-overlay] {
        z-index: 2000;
    }
    [data-rsbs-backdrop] {
        z-index: 1999;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .order-container{
        height: 45.3em;
        // overflow: scroll;
        padding-left: 0rem;
        padding-right: 0rem;
        .cart-title{
            height: 11em;
            padding-top: 7em;
            padding-bottom: 1em;
            background: #FFFFFF;
            .title-col{
                display: flex;
                align-items: center;
                ._label{
                    &.--title{
                        font-size: 3rem;
                        font-family: "roboto-medium";
                        color:#3A2E2E ;
                    }
                }
    
            }
        }
        .food-order{
            margin-top: 0.8em;
            margin-bottom: 1em;
            background: #FFFFFF;
            ._label{
                &.--name{
                    margin: 1em 0em;
                    font-family: "roboto-medium";
                    // //font-weight: 600;
                    font-size: 1.6rem;
                    // text-transform: capitalize;
                }
                &.--data{
                    // //font-weight: 400;
                    font-size: 1.4rem;
                    color: $text-color--primary;
                }
                &.--data2{
                    // //font-weight: 400;
                    font-size: 1.4rem;
                    color: $text-color--primary;
                }
                &.--order_number{
                    font-family: "roboto-medium";
                    font-size: 1.8rem;
                    color: $text-color--primary;
                }
                &.--total_paid{
                    font-family: "roboto-medium";
                    font-size: 1.6rem;
                    color: $text-color--primary;
                }
                &.--total_price{
                    font-family: "roboto-medium";
                    font-size: 1.6rem;
                    color: $text-color--primary;
                }
            }
            .border-upline{
                border-top: 0.1em solid #F7E8DB ;
            }
        }
        .image-div{
            height: 67vh;
            display: flex;
            justify-content: center;
            background: #FFFFFF;
            .logo-image{
               margin-top: 5.5em;
               width: 168px;
               height: 168px;
               opacity: 0.3;
               
               @media (max-width: 500px) {
                margin-top: 1.5em;
               }
            }
            .loading-div {
                margin-top: -1em;
                font-size: 20px;
                font-weight: 600;
                font-style: italic;
                letter-spacing: 0.05em;
                color: #6351514f;
                font-family: "roboto-bold";
                // text-transform: uppercase;
            }

            .loading-lottie{
                // margin-top: 5.5em;
                width: 220px;
                margin-bottom: 0em;

                &.--two {
                    width: 100px;
                    margin-top: -1.5em;
                }
            }
        }
        .step-div{
            margin-top: 1em;
            .step-row{
                height: 2em;
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
                .step-line{
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .form-check-input[type=radio] {
                        display: flex;
                        width: 1.45rem;
                        height: 1.45rem;
                        align-items: center;
                        background: #E8E7E0;
                        justify-content: center;
                        border-color: #E8E7E0;
                    }
                    .form-check-input[type=radio]:checked {
                        background-color: #373A2E;
                    }
                    .form-check-input:checked {
                        border-color: #373A2E;
                    }
                    .form-check-input[type=radio]:checked:after {
                         border-color: #373A2E; 
                         background-color: white; 
                    }
                }
                .line{
                    width: 78%;
                    height: 0.6em;
                    position:absolute;
                    transition: width 2s ;
                    background: #E8E7E0;
                    .p-progressbar-value{
                        background: #373A2E;
                        .p-progressbar-label{
                            display: none;
                        }
                    }
                }
            }
            ._label{
                &.--text{
                    font-size: 1.4rem;
                    color: $text-color--primary;
                }
                &.--step{
                    display: flex;
                    // //font-weight: 500;
                    font-size: 1.4rem;
                    align-items: center;
                    justify-content: center;
                    color: $text-color--primary;
                }
                &.--code{
                    text-align: center;
                    width: 3em;
                    margin-left: 0.2em;
                    // //font-weight: 500;
                    border-radius: 0.3em;
                    background: #dfdfdf;
                    font-size: 0.75em !important;
                } 
            }
            .food-col{
                display: flex;
                align-items: center;
            }
        }
        .food-detail{
            background: #FFFFFF;
            padding: 0.5em 0em 0.5em 0em;
            border-bottom: 0.1rem solid #F7E8DB;
            .food-image{
                display: flex;
                margin-bottom: 1em;
                align-items: center;
                justify-content: center;
                filter: drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.25));
                .img{
                    width:5.8125em;
                    height: 5.8125em;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
            .food-data{
                display: grid;
                overflow: auto;
                align-items: center;
            }
            ._label{
                &.--text{
                    font-size: 1.4rem;
                  color: #3A2E2E;
                } 
                 &.--food_name{
                    font-size: 1.8rem;
                    line-height: normal;
                    color: #3A2E2E;
                    font-family: "roboto-medium";
                } 
                &.--name{
                    // //font-weight: 600;
                    font-size: 1.75rem;
                    color: $text-color--primary;
                    margin-bottom: 2em;
                }
                &.--option{
                    // //font-weight: 400;
                    font-size: 1.2rem;
                    color: #83829A;
                }
                &.--quantity{
                    // //font-weight: 400;
                    font-size: 1.2rem;
                    color: $text-color--secondary;
                }
                &.--label{
                    padding: 0.2em;
                    text-align: center;
                    // //font-weight: 500;
                    font-size: 1.2rem;
                    border-radius: 0.3rem;
                    background: #FAE6E6;
                    border: 1px dashed #C3C4CD;
                    color: #ED1C24;
                }
            }
        }
        .claim-container{
            // padding-bottom: 3em;
            ._label{
                &.--step{
                    display: flex;
                    // //font-weight: 500;
                    font-size: 1.4rem;
                    align-items: center;
                    justify-content: center;
                    color: $text-color--primary;
                }
                &.--code{
                    font-size: 0.75em !important;
                    // //font-weight: 500;
                    margin-left: 0em;
                    background: #dfdfdf;
                    height: 1.5em;
                    width: 3.5em;
                    border-radius: 0.3em;
                } 
            }
        }
    }
    .order-container::-webkit-scrollbar{
        display: none;
    }


} 



//loading page
.loading-page {
  background: $background-color--third;
  height: 100%;
  width: 100%;
}

.loading-overlay{
  backdrop-filter: blur(0px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background-color--primary;
  transition: backdrop-filter 0.6s !important;

  &.--end{
    animation-name: hide-up;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    animation-fill-mode: both;
  }
}

.loading-overlay-two{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background-color--primary;
  }

.loading-overlay2{
  backdrop-filter: blur(0px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fdfcf5;
  
  .loading-div {
    margin-top: -1.8em;
    font-size: 20px;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 0.05em;
    color: #6351514f;
}

.loading-lottie{
    width: 220px;
    margin-bottom: 0em;
}

  &.--end{
    animation-name: hide-up;
    animation-duration: 0.8s;
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    animation-fill-mode: both;
  }
}

.animated-logo{
  opacity: 0;
  position: relative;
  width: 100px;
  height: 100px;
  animation-name: fade-in;
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  animation-fill-mode: both;
}

.term-container{
    padding: 1em 1em;
    ._label{
        &.--tag{
            font-size: 12px;
            color: #191919;
        }
        &.--title{
            font-size: 16px;
            color: #191919;
            font-family: "roboto-medium";
        }
        &.--big_title{
            font-size: 22px;
            color: #191919;
            font-family: "roboto-medium";
        }
    }
    ._p{
        font-size: 12px;
        color: #191919;
    }
    .choose-btn{
        width: auto;
        margin: 0.5em;
        font-size: 12px;
        border-radius: 0.5em;
        background: transparent;
        &.active{
            color: #ed1c24;
            background: #fff7e3;
            border: 2px solid #e26368;
        }
    }
}


//home page 
.home-body-div{
    // background-image: url('https://media.istockphoto.com/id/1200713909/vector/red-chinese-pattern-oriental-background-vector.jpg?s=612x612&w=0&k=20&c=vUtvQ3w2TxQkEVEMv2x9taLUNWILnNfdoQxpKfM5Y48=');
    // background:#e6564c ;
    // background: linear-gradient(45deg, #e26368,#e26368);
    // height: 43em !important;
    border-radius: 2em 2em 0em 0em;
    // position: absolute;
    z-index: 99;
    bottom: 0;
    padding: 0.5em 1em !important;
    height: 100%;
    // background-size: cover;
    // background-position: center;
}
.option-card-container{
    background: #ffffff;
    border-radius: 1em;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    // background: #f5f1e6;
    padding: 1em 0em 0.5em 0em !important;
    position: relative;
    .img-div{
        height: 85px;
        width: 85px;
        margin: auto;
        img{
            pointer-events: none;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    ._label{
        &.--title{
            font-size: 16px;
            margin-top: 0.5em;
            font-weight: 700;
            color: #433e3d;
            font-family: monospace;
        }
        &.--time{
            position: absolute;
            font-size: 14px;
            font-weight: 700;
            color: #FFF;
            font-family: monospace;
            left: 0;
            top: 45%;
            margin: auto;
            width: 100%;
            background: rgba(255, 0, 0, 0.649);
            z-index: 88;
        }
    }
}
.banner-div{
    width: 100%;
    height: 23em;
    img{
        pointer-events: none;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
.my-order_list-div{
    background: #ffffff;
    margin-top: 1em;
    padding: 0.6em;
    border-radius: 0.6em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
//     transform-origin: right center; /* 设置旋转中心为图像右侧中心点 */
//   transform: rotateY(40deg); 
//     transition: transform 1s ease;
    .text-order-div{
        background: #e26368;
        border-radius: 0.4em 0em;
        border: 2px solid black;
    }
    ._label{
        &.--title{
            font-size: 14px;
            font-weight: 600;
        }
         &.--text{
            font-size: 14px;
         color: #FFFFFF;
        }
    }
}
.member-card-div{
    background: #ffffff;
    margin-top: -2em;
    padding: 0.6em;
    border-radius: 0.6em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    z-index: 999;
    position: relative;
    .member-icon-div{
        background: #fff7eb;
        border-radius: 50%;
        height: 2.8em;
        width: 2.8em;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #e26368;
    }
    ._label{
        &.--point{
            font-size: 14px;
            font-weight: 600;
        }
        &.--details{
            font-size: 12px;
            color: #9c9e9f;
        }
    }
}

.member-shop{
    background: #ffffff;
    padding: 0.6em;
    margin-top: 1em;
    border-radius: 0.6em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    ._label{
        &.--text{
            font-size: 16px;
            font-weight: 600;
        }
        &.--message{
            font-size: 12px;
            color: #9c9e9f;
        }
    }
    .gift-div{
        height: 85px;
        width: 85px;
        margin: auto;
        img{
            object-fit: cover;
            height: 100%;
            width: 100%;
            pointer-events: none;
        }
    }
    .view_all_btn{
        margin-top: 1em;
        font-size: 12px;
        display: flex;
        justify-content: center;
        background: #e26368;
        color: #FFF;
        border-radius: 0.5em;
        padding: 0.2em 1.9em;
        border: none;
        border: 2px solid black;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    }
}

 .stamp-icon-div{
    height: 4em;
    width: 4em;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        pointer-events: none;
    }
}

//home page  close

